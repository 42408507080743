<template>
  <div>
    <v-text-field
      clearable
      hide-details
      outlined
      append-icon="mdi-crosshairs-gps"
      ref="textbox-autocomplete"
      v-model="text"
      :rules="rules"
      :required="required"
      :autofocus="autofocus"
      :prefix="prefix"
      :readonly="readonly"
      :dense="dense || $vuetify.breakpoint.smAndDown"
      @focus="onFocusHandler"
      @blur="onBlurHandler"
      @click:append="setMyLocation"
      @click:clear="clearTextbox"
    >
    </v-text-field>

    <div
      v-if="showLocationDropdown"
      @click="setMyLocation"
      class="my-location-dropdown">
      <svg width="12" height="12">
        <circle cx="50%" cy="50%" r="6" fill="#4286F5"/>
      </svg>
      My Location
    </div>

    <v-progress-linear
      v-if="geolocationPending"
      indeterminate
      height="1"
      class="mx-2"
      style="width: 95%"
      color="primary"
    ></v-progress-linear>
    <div v-if="geolocationError.length">
      {{ geolocationError }}
    </div>
  </div>
</template>

<script>
  import places from 'places.js'
  import { algolia as algoliaSettings } from '../settings'

  export default {
    props: {
      autofocus: {
        type: Boolean,
        default: false
      },
      required: {
        type: Boolean,
        default: false
      },
      dense: {
        type: Boolean,
        default: false
      },
      prefix: {
        type: String,
        default: 'Start'
      },
      boundsSW: {
        type: Object,
        default: () => ({ lat: 50.95, lng: -1.41 })
      },
      boundsNE: {
        type: Object,
        default: () => ({ lat: 51.15, lng: -1.21 })
      },
      placeDetails: {
        type: Object,
        default: () => ({ name: '', lat : '', lng : '', type: '-' })
      },
      rules: { // for validation
        type: Array,
        default: () => []
      },
      placeType: {
        type: String,
        default: "'origin'"
      },
      storeMutation: {
        type: String,
        default: 'SET_INPUTS_ORIGIN'
      },
      geolocationPending: {
        type: Boolean,
        default: false
      },
      geolocationError: {
        type: String,
        default: ""
    },
  },
  data () {
    return {
      text: '',
      lat: 0,
      lng: 0,
      type: '',
      readonly: false,
      showLocationDropdown: false
    }
  },
  computed: {
    place () {
      return {
        name: this.text,
        lat: this.lat,
        lng: this.lng,
        type: this.type
      }
    }
  },
  watch: {
    lat () {
      this.$emit('place-selected', this.place)
    },
    placeDetails () {
      this.text = this.placeDetails.name
      this.lat = this.placeDetails.lat
      this.lng = this.placeDetails.lng
    },
    // geolocationPending (bool) {
    //   // v-input__icon--append
    // }
  },
  methods: {
    initGooglePlaces () {
      let textboxEL = this.$refs['textbox-autocomplete']
      let searchBounds = new window.google.maps.LatLngBounds(
                              new window.google.maps.LatLng(this.boundsSW.lat, this.boundsSW.lng),
                              new window.google.maps.LatLng(this.boundsNE.lat, this.boundsNE.lng),
                            );
      if (textboxEL) {
        let inputEl = textboxEL.$el.querySelectorAll('[type=text]')
        let autocomplete = new window.google.maps.places.Autocomplete(inputEl[0], {
          bounds: searchBounds,
          componentRestrictions: { country: 'ng' }
        });
        // autocomplete.bindTo('bounds', searchBounds);
        // Avoid paying for data that you don't need by restricting the set of
        // place fields that are returned
        // autocomplete.setFields(['geometry', 'name', 'address_component']);
        autocomplete.setFields(['geometry', 'name']);
        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace()
          if (place) {
            this.text = place.name
            this.lat = place.geometry.location.lat()
            this.lng = place.geometry.location.lng()
          }
        });
      }
    },
    initAlgolia () {
      let textboxEL = this.$refs['textbox-autocomplete']
      let placesAutocomplete = places({
        ...algoliaSettings.options,
        container: textboxEL.$el.querySelectorAll('[type=text]'), //or can use document.querySelector('#' + this.id),
      });
      placesAutocomplete.configure(algoliaSettings.config)
      placesAutocomplete.on('change', e => {
        placesAutocomplete.setVal(e.suggestion.name)
        this.text = e.suggestion.name
        this.lat = e.suggestion.latlng.lat
        this.lng = e.suggestion.latlng.lng
          // fetch update about matching zone?
      });
      placesAutocomplete.on('clear', () => {
        this.clearTextbox()
      });
      this.placesAutocompleteRef = placesAutocomplete
    },
    onFocusHandler(event) {
      this.text = this.text ? this.text : '' // remove null
      if (this.readonly) {
        this.$refs['textbox-autocomplete'].blur()
      }
      console.log("onFocusHandler -> this.text", this.text)
      this.showLocationDropdown = !(this.text + '').length
      this.$emit('focus', event)
    },
    onBlurHandler() {
      // if (this.readonly) {
      //   this.$refs['textbox-autocomplete'].blur()
      // }
      setTimeout(() => this.showLocationDropdown = false, 100)
      // this.$emit('focus', event)
    },
    setMyLocation () {
      this.$emit('use-my-location')
    },
    clearTextbox () {
      console.log("clearTextbox -> clearTextbox", this.placesAutocompleteRef)
      setTimeout(() => this.$refs['textbox-autocomplete'].focus(), 20) // this slight delay empties the pac-cotnainer
      this.$refs['textbox-autocomplete'].blur()
      this.text = ''
      this.lat = 0
      this.lng = 0
      this.readonly = false
      if (this.placesAutocompleteRef) {
        this.placesAutocompleteRef.setVal('')
      }
    }
  },
  beforeMount () {
    this.text = this.placeDetails.name
    this.lat = this.placeDetails.lat
    this.lng = this.placeDetails.lng
  },
  mounted () {
    if (window.google) {
      setTimeout(() => this.initGooglePlaces(), 500)
      return
    }
  },

}
// https://itnext.io/anyway-this-is-how-to-use-v-model-with-vuex-computed-setter-in-action-320eb682c976
</script>

<style lang="scss">
.v-text-field__prefix {
  padding-right: 8px;
  width: 50px;
  font-weight: 700;
  font-size: 0.9em;
}

.algolia-places, .algolia-places input {
  border:none;
}
.algolia-places .ap-input-icon {
  display: none;
}
.ap-dropdown-menu.ap-with-places {
  min-width: 314px;
  margin-left: -11px;
  margin-top: 11px;
}

.pac-container {
  // background-color: #fff;
  // position: absolute!important;
  // z-index: 1000;
  // border-radius: 2px;
  // border-top: 1px solid #d9d9d9;
  // font-family: Arial,sans-serif;
  // box-shadow: 0 2px 6px rgb(0 0 0 / 30%);
  // -moz-box-sizing: border-box;
  // -webkit-box-sizing: border-box;
  // box-sizing: border-box;
  // overflow: hidden;
  min-width: 294px;
  margin-left: -50px;
}

.my-location-dropdown {
  position: absolute;
  left: 0;
  right: 0;
  // border: solid 1px #aaa;
  cursor: pointer;
  padding: 10px;
  background-color: rgb(255, 255, 255, 0.9);
  z-index: 99;
  box-shadow: -1px 7px 12px 3px rgb(0 0 0 / 10%);
}

</style>