<template>
  <div>

    <!-- <div class="d-flex">
      <div>A</div>
      <v-spacer></v-spacer>
      <div>B</div>
    </div> -->
    <div class="d-flex mb-4">
      <!-- <v-icon classa="mr-2">mdi-clock-outline</v-icon> -->
      <div>
      <v-btn text small @click="showTripWhenOptions">
        <v-icon class="mr-2">mdi-clock-outline</v-icon>
        {{ whenMessage }}
        <!-- <v-icon small class="ml-1" v-if="!isShowTripWhenOptions">mdi-chevron-right</v-icon>
        <v-icon small class="ml-1" v-if="isShowTripWhenOptions">mdi-close</v-icon> -->
      </v-btn>
      </div>
      <v-spacer></v-spacer>
      <div>
      <v-btn text small @click="showTripAdvOptions">
        <v-icon class="mr-1" v-if="isShowTripAdvOptions">mdi-chevron-up</v-icon>
        <v-icon class="mr-1" v-else>mdi-chevron-right</v-icon>
        Options
      </v-btn>
      </div>
    </div>
    <v-row v-if="isShowTripWhenOptions">
      <v-col cols="12">
        <v-btn-toggle
          v-model="toggleDepartArrive"
          mandatory
          @change="checkDateTimeIsNo">
          <v-btn small value="now">
            Now
          </v-btn>
          <v-btn small value="depart">
            Depart
          </v-btn>
          <v-btn small value="arrive">
            Arrive
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="8">
        <v-select
          class="my-0 py-0"
          hide-details
          outlined dense
          v-model="menuDate"
          :items="dates"
          label="Select Date">
        </v-select>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="menuTime"
          hide-details
          outlined dense
          class="my-0 py-0"
          type="time"
          step="300"
          label="Select Time"
          NAprepend-icon="access_time">
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="isShowTripAdvOptions">
      <v-col cols="12">
        <v-text-field
          label="Max Changes"
          outlined
          dense
          v-model="maxTransfers"
          min="0"
          max="10"
          type="number"
        ></v-text-field>
        <v-select
          v-model="maxWalkDistance"
          :items="maxWalkDistanceList"
          :hint="`${maxWalkDistance.label}, ${maxWalkDistance.value}`"
          item-text="label"
          item-value="value"
          label="Max Walking Time (min)"
          outlined
          dense
        ></v-select>
        <v-text-field
          label="Number Of Itineraries"
          outlined
          dense
          v-model="numItineraries"
          type="number"
        ></v-text-field>
        <v-btn block outlined color="primary" @click="updateOptions">Update</v-btn>
        <!-- <p class="note">These trip options preferences</p> -->
      </v-col>
    </v-row>

  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import { getDatesFromNow, monthsShort } from '../helpers/utils'

  const { mapState, mapMutations } = createNamespacedHelpers('trip-planner')

  export default {
    data() {
      return {
        dates: getDatesFromNow(14),
        menuTimePicker: false,
        menuDatePicker: false,
        isShowTripWhenOptions: false,
        isShowTripAdvOptions: false,
        maxTransfers: this.$store.state['trip-planner'].inputs.options.maxTransfers,
        maxWalkDistanceList: this.$store.state['trip-planner'].inputs.maxWalkDistanceList,
        maxWalkDistance: this.$store.state['trip-planner'].inputs.options.maxWalkDistance,
        minTransferTime: this.$store.state['trip-planner'].inputs.options.minTransferTime,
        numItineraries: this.$store.state['trip-planner'].inputs.options.numItineraries
      }
    },
    computed: {
      ...mapState({
        date: state => state.inputs.when.date,
        time: state => state.inputs.when.time,
        type: state => state.inputs.when.type,
        // maxTransfers: state => state.inputs.options.maxTransfers,
        // maxWalkDistance: state => state.inputs.options.maxWalkDistance,
        // minTransferTime: state => state.inputs.options.minTransferTime,
        // numItineraries: state => state.inputs.options.numItineraries
      }),
      menuDate: {
        get () {
          return this.date
        },
        set (value) {
          this.setDate(value)
        }
      },
      menuTime: {
        get () {
          return this.time
        },
        set (value) {
          this.setTime(value)
        }
      },
      toggleDepartArrive: {
        get () {
          return this.type
        },
        set (value) {
          this.setType(value)
        }
      },
      dateIsToday () {
        if (this.date === (new Date()).toISOString().substr(0,10)) {
          return true
        }
        return false
      },
      timeIsNow () {
        if ((this.date + "T" + this.time) === (new Date()).toISOString().substr(0,16)) {
          return true
        }
        return false
      },
      dateMonth () {
        let monthNo = (new Date(this.date)).getMonth()
        return monthsShort[monthNo]
      },
      dateDate () {
        return parseInt(this.date.substr(8,2))
      },
      whenMessage () {
        let msg = '-'
        if (this.type === 'now') {
          msg = 'Leave now'
        } else if (this.type === 'depart') {
          msg = 'Depart at ' + this.time + (this.dateIsToday ? ' today' : ' on ' + this.dateDate + ' ' + this.dateMonth)
        } else if (this.type === 'arrive') {
          msg = 'Arrive at ' + this.time + (this.dateIsToday ? ' today' : ' on ' + this.dateDate + ' ' + this.dateMonth)
        }
        return msg
      }
    },
    methods: {
      ...mapMutations({
        setDate: 'SET_INPUTS_WHEN_DATE',
        setTime: 'SET_INPUTS_WHEN_TIME',
        setType: 'SET_INPUTS_WHEN_TYPE',
        setOptions: 'SET_INPUTS_OPTIONS',
      //  setButtonMsg: 'SET_INPUTS_WHEN_MSG',
      }),
      checkDateTimeIsNo () {
        if (this.toggleDepartArrive === 'now') {
          this.menuDate = (new Date()).toISOString().substr(0,10)
          this.menuTime = (new Date()).toLocaleTimeString("en-US", {timeZone: "Africa/Lagos"}).substr(0,5)
        }
      },
      updateOptions () {
        this.setOptions({
          maxTransfers: this.maxTransfers,
          maxWalkDistance: this.maxWalkDistance,
          minTransferTime: this.minTransferTime,
          numItineraries: this.numItineraries
        })
        this.isShowTripAdvOptions = false
        this.$store.commit('trip-planner/SET_TRIP_INDEX', -1)
        this.$emit('plan-trip-call')
      },
      showTripWhenOptions () {
        this.isShowTripWhenOptions = !this.isShowTripWhenOptions
        this.isShowTripAdvOptions = false
      },
      showTripAdvOptions () {
        this.isShowTripWhenOptions = false
        this.isShowTripAdvOptions = !this.isShowTripAdvOptions
      },
      // setMaxWalkDistance (val) {
      //   this.setOptions({ maxWalkDistance: val })
      // },
      // setMaxTransfers (val) {
      //   this.setOptions({ maxTransfers: val })
      // },
      // setMinTransferTime (val) {
      //   this.setOptions({ minTransferTime: val })
      // },
      // setNumItineraries (val) {
      //   this.setOptions({ numItineraries: val })
      // }
    },
    watch: {
      timeIsNow (bool) {
        if (!bool && this.toggleDepartArrive === 'now') {
          this.toggleDepartArrive = 'depart'
        }
      },
      dateIsToday (bool) {
        if (!bool && this.toggleDepartArrive === 'now') {
          this.toggleDepartArrive = 'depart'
        }
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>