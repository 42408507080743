<template>
<v-container class="px-0">

  <!-- inputs -->
  <v-form
    ref="trip-inputs-form"
    class="trip-inputs-form"
    v-model="tripInputsValid"
    lazy-validation>

    <div style="position: relative">
      <div class="inputs-fromto" :class="{ 'input-dense': stage !== 'inputs' }">
        <div class="inputs-fromto--from">
          <places-autocomplete
            :required="false"
            id="textfield-orig"
            :place-details="origin"
            outterIcon="mdi-directions"
            place-type="origin"
            prefix="Start"
            :dense="stage !== 'inputs'"
            store-mutation="SET_INPUTS_ORIGIN"
            ATshow-directions="showDirections"
            @place-selected="origSelected"
            @use-my-location="useMyLocation('orig')"
          />
        </div>
        <div
          class="inputs-fromto--swap"
          @click="swapStartEndPoints">
          <v-btn icon small>
            <v-icon>mdi-swap-vertical-bold</v-icon>
          </v-btn>
        </div>
        <div class="inputs-fromto--to">
          <places-autocomplete
            :required="false"
            id="textfield-dest"
            :place-details="destination"
            outterIcon="mdi-directions"
            place-type="destination"
            prefix="End"
            :dense="stage !== 'inputs'"
            store-mutation="SET_INPUTS_DEST"
            ATshow-directions="showDirections"
            @place-selected="destSelected"
            @use-my-location="useMyLocation('dest')"
          />
        </div>
      </div>
    </div>

    <div class="trip-when-options">
      <trip-options @plan-trip-call="planJourneyStore"></trip-options>
      <!-- <v-spacer></v-spacer>
      <v-btn text small @click="showTripOptions = !showTripOptions">
        <v-icon class="mr-1">mdi-chevron-right</v-icon>
        Options
      </v-btn> -->
    </div>

    <!-- <div class="trip-options" v-if="showTripOptions">
      <trip-options></trip-options>
    </div> -->

  </v-form>
  <!-- inputs END -->


    <div class="submit-button-container">
      <!-- <v-btn
        block
        color="primary"
        :small="$vuetify.breakpoint.smAndDown"
        :loading="loading"
        :disabled="loading"
        @click="planJourneyHandler">PLAN JOURNEY</v-btn> -->

        <v-alert
          v-if="tripInputsError"
          class="mt-5"
          type="error">
          Choose a <strong>Start</strong> and <strong>End</strong> point!
        </v-alert>
    </div>

    <v-progress-linear
      indeterminate
      color="primary"
      v-if="loading"></v-progress-linear>

    <v-alert
      v-if="fetchTripError"
      class="mt-5 mx-3"
      type="error">
      Network error!
    </v-alert>

      <!-- trip suggestions -->
      <div v-if="results.length && tripIndex < 0">
        <div class="section-title section-title-suggestions">Trip Suggestions</div>
        <v-alert
          iconNA="mdi-bus"
          prominentNA
          type="error" v-if="!hasTransitResults">
          <!-- <v-icon large>mdi-bus</v-icon> -->
          Sorry we are unable to find any transit journeys between your chosen start and end points.
        </v-alert>
        <trip-suggestion
          v-for="(trip, tripIndex) in results"
          :key="'trip-' + tripIndex"
          :trip="trip"
          :origin="origin"
          :destination="destination"
          :tripIndex="tripIndex"
        ></trip-suggestion>
        <!-- <div style="margin:10px">
        <v-btn
          color="black white--text"
          astyle="padding:30px"
          large
          depressed
          block
          :title="uberButtonURL">
          <img
            class="uber-logo"
            src="@/assets/uber_logo_icon_white.svg"
            alt="UBER">
          RIDE WITH UBER
        </v-btn>
        </div> -->
      </div>
      <div v-else>
        <!-- <v-btn text small block color="primary" class="back-to-suggestions">
          <v-icon small>mdi-arrow-left</v-icon> Back to trip suggestions
        </v-btn> -->
      </div>

      <!-- trip details -->
      <div v-if="results.length && tripIndex >= 0 && results.length > tripIndex">
        <div class="section-title">
          <v-btn icon @click="resetTripIndex "><v-icon small>mdi-arrow-left</v-icon></v-btn>
          Trip Details
        </div>
        <trip-suggestion
          :trip="results[tripIndex]"
          :tripIndex="tripIndex"></trip-suggestion>
        <trip-details-non-transit
          v-if="results[tripIndex] && results[tripIndex].isCar"
          :tripDetails="results[tripIndex]"></trip-details-non-transit>
        <trip-details
          v-else
          :tripDetails="results[tripIndex]"></trip-details>
      </div>

      <br><br>

</v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import PlacesAutocomplete from '@/components/PlacesAutocomplete'
// import TripWhen from '@/components/TripWhen'
import TripOptions from '@/components/TripOptions'
import TripSuggestion from '@/components/TripSuggestion'
import TripDetails from '@/components/TripDetails'
import TripDetailsNonTransit from '@/components/TripDetailsNonTransit'

const { mapState, mapMutations, mapActions, mapGetters } = createNamespacedHelpers('trip-planner')
import { timeConverter } from '../helpers/utils'

export default {
  components: {
    PlacesAutocomplete, TripOptions, TripSuggestion, TripDetails, TripDetailsNonTransit
  },
  data() {
    return {
      showNow: true,
      showTripOptions: false,
      showTripWhenOptions: false,
      tripInputsValid: true,
      tripInputsError: false
    }
  },
  computed: {
    ...mapState({
      origin: state => state.inputs.origin,
      destination: state => state.inputs.destination,
      tripWhen: state => state.inputs.when,
      modes: state => state.inputs.modes,
      stage: state => state.stage,
      tripIndex: state => state.tripIndex,
      results: state => state.results,
      loading: state => state.loading,
      fetchTripError: state => state.error,
      responseReceived: state => state.responseReceived,
    }),
    ...mapGetters([
      'inputsValid',
      'inputsOrigValid',
      'inputsDestValid',
      'inputsWhenValid',
      'inputsModesValid',
    ]),
    myGeolocation () {
      return this.$store.state.geolocation
    },
    inputsCombined () {
      return this.origin.lat + "|" + this.destination.lat + "|" + this.tripWhen.date + "|" + this.tripWhen.time + "|" + this.tripWhen.type
    },
    hasTransitResults () {
      // .legs[0].mode === 'CAR' || trip.legs[0].mode === 'DRIVE'"
      return this.results.filter(trip => trip.legs.find(leg => leg.mode !== 'CAR')).length
    },
    uberButtonURL () {
      // if (aa) {

      // }
      let { lat:origLat, lng:origLng, name:origName } = this.origin
      let { lat:destLat, lng:destLng, name:destName } = this.destination
      let url = `https://m.uber.com/ul/?client_id=${process.env.VUE_APP_UBER_CLIENT_ID}&action=setPickup&pickup[latitude]=${origLat}&pickup[longitude]=${origLng}&pickup[nickname]=${origName}&pickup[formatted_address]=${origName}&dropoff[latitude]=${destLat}&dropoff[longitude]=${destLng}&dropoff[nickname]=${destName}&dropoff[formatted_address]=${destName}&product_id=a1111c8c-c720-46c3-8534-2fcdd730040d`
      return url
    }
  },
  filters: {
    formatDuration: function(secs) {
      return  Math.round(secs/60);
    },
    formatTime: function(date) {
      return timeConverter(date);
    },
  },
  methods: {
    ...mapMutations({
      setOrigin: 'SET_INPUTS_ORIGIN',
      setDestination: 'SET_INPUTS_DESTINATION',
    }),
    ...mapActions({
      planJourneyStore: 'planJourney'
    }),
    toggleDateTimeOptions () {
      this.showDateTimeOptions = !this.showDateTimeOptions
    },
    swapStartEndPoints () {
      let orig = Object.assign({}, this.destination)
      let dest = Object.assign({}, this.origin)
      this.setOrigin(orig)
      this.setDestination(dest)
    },
    origSelected (place) {
      if (place.lat) {
        this.setOrigin(place)
        // only pan the map if it comes from textbox not map
        let nameArr = place.name.split(",")
        if (nameArr.length > 1) {
          if (!isNaN(nameArr[0]) && !isNaN(nameArr[1])) {
          // if (validationLatitudeLongitude.latLong(nameArr[0], nameArr[1])) {
            return
          }
        }
        this.$store.commit('map/SET_MAP_LATLNG', place)
        this.$store.commit('map/SET_PAN', true)
        // this.planJourneyHandler()
      }
    },
    destSelected (place) {
      if (place.lat) {
        this.setDestination(place)
        let nameArr = place.name.split(",")
        if (nameArr.length > 1) {
          if (!isNaN(nameArr[0]) && !isNaN(nameArr[1])) {
          // if (validationLatitudeLongitude.latLong(nameArr[0], nameArr[1])) {
            return
          }
        }
        this.$store.commit('map/SET_MAP_LATLNG', place)
        this.$store.commit('map/SET_PAN', true)
      }
    },
    useMyLocation (textfieldType = 'orig') {
      this.$store.dispatch('geolocation/watchLocation')
        .then(coordinates => {
          console.log("useMyLocation -> coordinates", coordinates)
          this.$store.commit('map/SET_MAP_LATLNG', coordinates)
          this.$store.commit('map/SET_PAN', true)
          if (textfieldType === 'orig') {
            this.setOrigin({ name: 'My Location', lat: coordinates.lat, lng: coordinates.lng })
          } else {
            this.setDestination({ name: 'My Location', lat: coordinates.lat, lng: coordinates.lng })
          }
        })
        .catch(err => {
          console.log("TCL: useMyLocation -> err", err)
          this.snackbarActive = true
          this.snackbarMsg = err + ' - Enable location'
        })
    },
    inputsReady () {
      return this.origin.lat && this.destination.lat
    },
    planJourneyHandler () {
      this.tripInputsError = false
      if (this.inputsReady()) {
        this.planJourney()
        return
      }
      this.tripInputsError = true
    },
    planJourney () {
      console.log("planJourney -> planJourney")
      // check validation
      let params = {
        startName: this.origin.name,
        startLat: Number(this.origin.lat).toFixed(6),
        startLng: Number(this.origin.lng).toFixed(6),
        finishName: this.destination.name,
        finishLat: Number(this.destination.lat).toFixed(6),
        finishLng: Number(this.destination.lng).toFixed(6),
        date: this.tripWhen.date,
        time: this.tripWhen.time,
        deparr: this.tripWhen.type,
        modes: this.modes.join(","),
      }
      this.resetTripIndex()
      this.$router.push({ name: 'trip-planner-inputs-ready-plan', params })
    },
    resetTripIndex () {
      this.$store.commit('trip-planner/SET_TRIP_INDEX', -1)
    }
  },
  watch: {
    inputsCombined () {
      if (this.inputsReady() && !this.loading) {
        this.planJourney()
      }
    }
  },
}
</script>
<style lang="css">
.trip-inputs-form {
  padding: 0 12px 0 12px;
}
.inputs-fromto {
  position: relative;
  /* border: solid 1px #000; */
  /* display: flex;
  flex-direction: column; */
}
.inputs-fromto::before {
  background: url('https://moovitapp.com/images/dot.svg');
  background-size: 4px;
  content: '';
  display: block;
  height: calc(100% - 70px);
  position: absolute;
  left: 8px;
  width: 4px;
  /* top: 11px; */
  /* bottom: 6px; */
  transform: translateY(50%);
}
.input-dense.inputs-fromto::before {
  height: calc(100% - 50px);
}
@media (max-width:640px) {
  .inputs-fromto::before {
    height: calc(100% - 50px);
  }
}
.inputs-fromto--from, .inputs-fromto--to {
  background-color: #f7f7f7;
  /* background: linear-gradient(140deg, rgba(255,255,255,1) 0%, rgba(195,255,243,0.1) 10%, rgba(24,191,222,0.1) 100%); */
  margin: 12px 0 12px 32px;
  border-radius: 4px;
  position: relative;
  font-size: .875rem;
  color: #292A30;
  -webkit-text-fill-color: initial;
}
.inputs-fromto--to {
  margin: 28px 0 12px 32px;
}
.inputs-fromto--from::before, .inputs-fromto--to::before {
  background: 0 0;
  border: 2px solid green;
  border-radius: 50%;
  content: '';
  display: block;
  height: 10px;
  position: absolute;
  top: 50%;
  left: 0;
  width: 10px;
  margin-left: -27px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.inputs-fromto--to::before {
  border-color: #ff1122;
  background-color:#ff1122;
}
.submit-button-container, .trip-when-options, .trip-options {
  /* margin-top: 25px; */
  /* padding:12px; */
  /* margin-left: 32px; */
}

.submit-button-container {
  margin-top: 25px;
}
.rowspan {
  flex: 2;
}

.section-title {
  background-color: #ddd;
  padding: 3px 5px;
}

.section-title-suggestions {
  padding: 8px 5px;
  padding-left: 45px;
}

.inputs-fromto--swap {
  position: absolute;
  right: 10px;
  top: 56px;
}
.input-dense .inputs-fromto--swap {
  top: 40px;
}
@media (max-width: 760px) {
  .inputs-fromto--swap {
    top: 40px;
  }
}
.uber-logo {
  border: 1px solid rgb(255, 255, 255);
  height: 26px;
  border-radius: 5px;
  margin-right: 9px;
  padding: 4px;
}
</style>