export const START_LATLNG = [6.465422, 3.406448]

export const algolia = {
  options: {
    appId: process.env.VUE_APP_ALGOLIA_APP_ID,
    apiKey: process.env.VUE_APP_ALGOLIA_API_KEY,
    openOnFocus: true,
    autocompleteOptions: {
      debug: true
    }
  },
  config:{
    // countries: ['ng'],
    aroundLatLng: START_LATLNG.join(","),
    aroundRadius: 50000
  },
}