<template>
  <div class="trip-details">
    <div
      v-for="(leg, legIndex) in tripDetails.legs"
      :key="'tripleg-' + legIndex"
      class="trip-detail"
      :class="{
        'trip-detail--first-leg': legIndex === 0,
        'trip-detail--last-leg': legIndex === tripDetails.legs-1 }">
    <div class="leg-container d-flex">
      <div class="trip-detail-time-mode">
        <div class="trip-detail-time">
        {{ leg.startTime | formatTime }}
        </div>
        <div class="trip-detail-mode">
          <template v-if="leg.mode === 'WALK'">
            <v-icon>mdi-walk</v-icon>
            <v-icon v-if="leg.duration >= 600" title="Keke">mdi-motorbike</v-icon>
          </template>
          <template v-else-if="leg.mode === 'BUS'">
            <v-icon>mdi-bus</v-icon>
          </template>
          <template v-else-if="leg.mode === 'TRAM'">
            <v-icon>mdi-tram</v-icon>
            </template>
          <template v-else>
            {{ leg.mode }}
          </template>
        </div>
      </div>
      <div
        @click="viewStepOnMap(leg.from.lat, leg.from.lon, leg.from.name)"
        :class="{ 'trip-detail-dots': true, 'mode-transit': leg.mode !== 'WALK' }">
        <div class="trip-detail-dots-point"></div>
      </div>
      <div class="trip-detail-place-info">
        <div
          class="trip-detail-place"
          @click="viewStepOnMap(leg.from.lat, leg.from.lon, leg.from.name)">{{ leg.from.name }} </div>
        <div
          class="change-service"
          v-if="tripDetails.legs[legIndex-1] && tripDetails.legs[legIndex-1].mode !== 'WALK' && tripDetails.legs[legIndex].mode !== 'WALK'">Change service</div>
        <div class="trip-detail-info">
          <div class="trip-detail-info-routeno">
            <template v-if="leg.mode !== 'WALK'">
              <span class="routeno">{{ leg.routeShortName }}</span>
              <span class="headsign">towards {{ leg.headsign }}</span>
            </template>
            <template v-else>
              Walk<span v-if="leg.duration >= 600">/Keke</span>
            </template>
          </div>
          <div
            v-if="tripDetails.fare && leg.tripId"
            class="trip-detail-fare">
            ₦ {{ findLegFare(leg) }}
          </div>
          <div
            v-if="leg.tripId"
            class="trip-detail-frequency">
            Every 5 minutes (approx)
          </div>
          <div class="trip-detail-info-durationdist">
            <v-btn
              small
              icon
              color="primary"
              v-if="[...(leg.intermediateStops ? leg.intermediateStops : []), ...(leg.steps ? leg.steps : []) ].length"
              @click="showSteps(legIndex)">
              <v-icon dark v-if="legIndex === activeLegIndex">mdi-chevron-up</v-icon>
              <v-icon v-else>mdi-chevron-down</v-icon>
            </v-btn>
            <span class="trip-detail-duration-metric">
              {{ leg.duration | formatDuration }} <span v-if="leg.duration >= 600">walk</span>,
              <span v-if="leg.duration >= 600">{{ leg.duration * 0.4 | formatDuration }} keke,</span>
            </span>
            <span class="trip-detail-distance-metric">{{ leg.distance | formatDistance }} </span>
            <span class="trip-detail-totalstops-metric" v-if="leg.intermediateStops">({{ leg.intermediateStops.length }} stops) </span>
          </div>
          <div v-if="legIndex === activeLegIndex && leg.mode === 'WALK'" style="margin-top:12px">
            <div
              v-for="(step, stepIndex) in leg.steps"
              :key="'step-' + stepIndex">
              <div
                class="trip-detail-walk-steps"
                @click="viewStepOnMap(step.lat, step.lon, step.relativeDirection + ' ' + step.streetName)">
                <!-- <v-icon small>mdi-arrow-{{(step.relativeDirection + '').toLowerCase()}}</v-icon> -->
                <!-- TO DO: Compile list of icons from http://dev.opentripplanner.org/apidoc/2.0.0/json_ApiRelativeDirection.html -->
                {{ (step.relativeDirection + '').toLowerCase() }}
                <span v-if="step.relativeDirection.indexOf('CONT') < 0">at</span>
                {{ step.streetName }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="legIndex === activeLegIndex">
    <div
      v-for="(interStop, interStopIndex) in leg.intermediateStops"
      :key="'interstop-' + interStopIndex"
      class="leg-extras d-flex">
      <div
        @click="viewStepOnMap(interStop.lat, interStop.lon, interStop.name)"
        class="trip-detail-time-mode">{{ interStop.arrival | formatTime }}</div>
      <div
        @click="viewStepOnMap(interStop.lat, interStop.lon, interStop.name)"
        :class="{ 'trip-detail-dots': true, 'mode-transit': leg.mode !== 'WALK' }">
        <div class="trip-detail-dots-point"></div>
      </div>
      <div
        class="trip-detail-place-info"
        @click="viewStepOnMap(interStop.lat, interStop.lon, interStop.name)">
        <div class="trip-detail-place">{{ interStop.name }}</div>
        <!-- <div class="trip-detail-agency-name" v-if="interStopIndex >= leg.intermediateStops.length-1 && leg.agencyName">
          Service run by <a :href="leg.agencyUrl" target="_blank">{{ leg.agencyName }}</a>
        </div> -->
      </div>
    </div>
    </template>

    </div>

     <!-- leg last -->
    <div class="trip-detail trip-detail--last-leg d-flex">
      <div class="trip-detail-time-mode">
        <div class="trip-detail-time">
        {{ tripDetails.legs[tripDetails.legs.length-1].endTime | formatTime }}
        </div>
      </div>
      <div class="trip-detail-dots">
        <div class="trip-detail-dots-point"></div>
      </div>
      <div class="trip-detail-place-info">
        <div class="trip-detail-place">{{ tripDetails.legs[tripDetails.legs.length-1].to.name }} </div>
      </div>
    </div>

    <!-- notice -->
    <v-alert type="info" text dense class="notice my-4 mx-2">
      Please note that all times and frequencies are estimates and the results shown do not take into account traffic, weather or other conditions.
    </v-alert>
    <div class="mx-2">
      <v-btn block outlined color="primary" @click="sendFeedback">
        Send Feedback
        <v-icon right>mdi-message-alert-outline</v-icon>
      </v-btn>
    </div>
  </div>
</template>


<script>
import { timeConverter } from '../helpers/utils'
// import { createNamespacedHelpers } from 'vuex'
// const { mapMutations } = createNamespacedHelpers('trip-planner')
import { fetchLegRoute } from '../api'

export default {
  props: {
    tripDetails: {
      type: Object,
      default: () => {}
    },
    startName: {
      type: String,
      default: 'Start'
    },
    endName: {
      type: String,
      default: 'End'
    }
  },
  filters: {
    formatDuration (secs) {
      let res = Math.round(secs/60)
      return (res ? Math.round(secs/60) : 1)  + " min";
    },
    formatTime (date) {
      return timeConverter(date);
    },
    formatDistance (meters) {
      if (meters > 500) {
        return (meters/1000).toFixed(1) + "km"
      }
      return Math.round(meters) + "m"
    }
  },
  data() {
    return {
      activeLegIndex: -1
    }
  },
  methods: {
    viewStepOnMap (lat, lng, place) {
      this.$store.commit('map/SET_POPUP', { active: false })
      this.$store.commit('map/SET_MAP_LATLNG', { lat, lng })
      setTimeout(() => {
        this.$store.commit('map/SET_POPUP', { active: true, content: `<strong>${place}</strong>`, latLng: [lat, lng] })
      }, 200)
    },
    findLegFare (leg) {
      if (!this.tripDetails.fare) {
        return
      }
      let fare = this.tripDetails.fare.details.regular.find(f => f.routes.find(r => r === leg.routeId))
      if (!fare.price) {
        return
      }
      let fullFare = Math.round((fare.price.cents || 0)/100)
      return Math.round(fullFare * 0.5) + "-" + fullFare
      // return Math.round((fare.price.cents || 0)/100)
    },
    // ...mapMutations({
    //   sendFeedback: 'SET_OPEN_FEEDBACK',
    // }),
    sendFeedback () {
      // let feedbackOpen = this.$store.state['trip-planner'].openFeedback
      this.$store.commit('trip-planner/SET_OPEN_FEEDBACK', true)
    },
    showSteps (legIndex = -1) {
      this.activeLegIndex = legIndex === this.activeLegIndex ? -1 : legIndex
    },
    // viewStepOnMap (lat, lng) {
    //   console.log("viewStepOnMap -> viewStepOnMap (lat, lng)", lat, lng)
    //   this.$store['map'].commit('SET_MAP_LATLNG', { lat, lng })
    // }
  },
  mounted () {
    this.$store.commit('trip-planner/RESET_LEG_ROUTES', {})
    // const legTransitGeometies = {}
    this.tripDetails.legs.forEach(async (leg, legIndex) => {
      if (!leg.intermediateStops) {
        return
      }
      const params = {
        startLat: leg.from.lat,
        startLng: leg.from.lon,
        endLat: leg.to.lat,
        endLng: leg.to.lon,
      }
      const query = { intermediateStops: leg.intermediateStops }
      leg.from.lat + "," + leg.from.lon
      leg.intermediateStops.map(x => x.lat + "," + x.lon)
      leg.to.lat + "," + leg.to.lon
      // SET_LEG_ROUTES
      let legRoutes = await fetchLegRoute(params, query)
      this.$store.commit('trip-planner/SET_LEG_ROUTES', { points: legRoutes.points, legIndex })

      // legTransitGeometies[legIndex] = legRoutes.points
      // console.log("mounted -> legRoutes", legRoutes)
    })
  },
}
</script>

<style lang="scss" scoped>

.trip-detail-time-mode {
  width: 70px;
  min-width: 70px;
  margin-right: 15px;
  // padding: 0 15px;
  text-align: right;
}
.trip-detail-mode {
  // height:100%;
  min-height: 140px;
  // border:solid 1px red;
  padding: 45px 0;
}
.trip-detail-info {
  min-height: 140px;
  // border:solid 1px yellow;
  padding: 45px 0;
}
.trip-detail-dots {
  width: 22px;
  text-align: center;
  background: url('https://moovitapp.com/images/dot.svg');
  background-repeat: repeat-y;
  background-position: center top;
  background-size: 6px;
  display: inline-block;
  margin-top: 12px;
  margin-bottom: -12px;
  width: 8px;
}
.trip-detail-dots.mode-transit {
  background-color: red;
  background-image: none;
}
.trip-detail--last-leg .trip-detail-dots {
  background-image: none;
}
.trip-detail-dots-point {
  background: 0 0;
  background-color: #fff;
  border: 3px solid #000;
  border-radius: 50%;
  content: '';
  display: block;
  height: 12px;
  width: 12px;
  margin-top: -6px;
  margin-left: -2px;
  // position: absolute;
  // top: 50%;
  // left: 0;
  // -webkit-transform: translateY(-50%);
  // -moz-transform: translateY(-50%);
  // -ms-transform: translateY(-50%);
  // transform: translateY(-50%);
}

.trip-detail-start-place {
  flex-grow: 4;
}
.trip-details-duration {
  background-color: blue;
  /* height: 100%; */
}
.trip-detail-info-routeno .headsign {
  opacity: 0.7;
  font-size: 0.9rem;
  margin-left: 3px;
}
.trip-detail-info-routeno .routeno {
  padding: 1px 3px;
  border:solid 1px #999;
}
.trip-detail-info-durationdist {
  font-size: 0.84rem;
  opacity: 0.8;
}
.leg-extras .trip-detail-dots-point {
  border: 3px solid red;
}

.leg-extras .trip-detail-place-info, .leg-extras .trip-detail-time-mode {
  font-size: 0.9rem;
  opacity: 0.7;
  padding: 4px;
}
.leg-extras-walk .trip-detail-dots-point {
  border: 0px solid #555;
  background-color: #555;
  height: 10px;
  width: 10px;
  border-radius: 0%;
  margin-left: 0;
}
.trip-detail-walk-steps {
  text-transform: capitalize;
  font-size: 0.9rem;
  opacity: 0.7;
  padding: 4px;
  transition: 0.3s all;
  background-color: #fff;
}
.trip-detail-walk-steps:hover {
  cursor: pointer;
  background-color: #eee;
}
.trip-detail-agency-name {
  font-size: 0.8rem;
  opacity: 0.6;
  padding: 10px 0 20px 0;
}

.trip-details-duration {
  margin-right: 15px;
  padding: 0 15px;
  font-size:1.5rem;
  font-weight: bold;
  white-space: nowrap;
  text-align: center;
}
.trip-details-duration span {
  font-size:0.8rem;
  font-weight: normal;
  margin-left: 2px;
  margin-top: -10px;
  display: block;
}
.trip-detail-fare {
  font-size: 14px;
  // font-weight: bold;
  color: rgb(165, 141, 1);
}
.trip-detail-place-info {
  margin-left: 15px;
}

.trip-detail-place-info .trip-detail-place {
  transition: 0.3s all;
}
.trip-detail-place-info .trip-detail-place:hover {
  cursor: pointer;
  background-color: #eee;
}

.trip-detail-frequency {
  font-size: 0.8rem;
  opacity: 0.5;
  padding: 3px 0;
}

.notice {
  font-size: 0.8rem;
}
.change-service {
  font-size: 0.8rem;
  font-style: italic;
  opacity: 0.8;;
}

</style>