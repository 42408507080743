<template>
  <div class="trip-details">
    <ul>
    <li
      v-for="(step, stepIndex) in steps"
      :key="'step-' + stepIndex"
      @click="viewStepOnMap(step.lat, step.lon, step.relativeDirection + ' ' + step.streetName)">
      <div style="display: flex">
        <div class="direction-icon">
          <v-icon v-if="stepIndex === 0" small color="green">mdi-circle</v-icon>
         <v-icon v-else>mdi-{{ step.relativeDirection | getDirectionIcon }}</v-icon>
        </div>
        <div class="direction-text">
          {{ step.relativeDirection.toLowerCase() }} {{ step.streetName }}
          <div class="direction-distance">{{ (step.distance/1000).toFixed(1) }} km</div>
        </div>
      </div>
    </li>
    <li @click="viewStepOnMap(destination.lat, destination.lon, destination.name)">
      <div style="display: flex">
        <div class="direction-icon">
          <v-icon small color="red">mdi-circle</v-icon>
        </div>
        <div class="direction-text">{{ destination.name }}</div>
      </div>
    </li>
    </ul>

  </div>
</template>


<script>
import { timeConverter } from '../helpers/utils'

export default {
  props: {
    tripDetails: {
      type: Object,
      default: () => {}
    },
    startName: {
      type: String,
      default: 'Start'
    },
    endName: {
      type: String,
      default: 'End'
    }
  },
  filters: {
    formatDuration (secs) {
      let res = Math.round(secs/60)
      return (res ? Math.round(secs/60) : 1)  + " min";
    },
    formatTime (date) {
      return timeConverter(date);
    },
    formatDistance (meters) {
      if (meters > 500) {
        return (meters/1000).toFixed(1) + "km"
      }
      return Math.round(meters) + "m"
    },
    getDirectionIcon (direction) {
      const arr = [
        { otp: 'DEPART', 	mdi: 'left' },
        { otp: 'HARD_LEFT', 	mdi: 'arrow-left' },
        { otp: 'LEFT', 	mdi: 'arrow-left' },
        { otp: 'SLIGHTLY_LEFT', 	mdi: 'arrow-top-left' },
        { otp: 'CONTINUE', 	mdi: 'arrow-up' },
        { otp: 'SLIGHTLY_RIGHT', 	mdi: 'arrow-top-right' },
        { otp: 'RIGHT', 	mdi: 'arrow-right' },
        { otp: 'HARD_RIGHT', 	mdi: 'arrow-right' },
        { otp: 'CIRCLE_CLOCKWISE', 	mdi: 'update' },
        { otp: 'CIRCLE_COUNTERCLOCKWISE', 	mdi: 'history' },
        { otp: 'ELEVATOR', 	mdi: 'left' },
        { otp: 'UTURN_LEFT', 	mdi: 'arrow-left' },
        { otp: 'UTURN_RIGHT', mdi: 'arrow-right' }
      ]
      let match = arr.find(x => x.otp === direction)
      if (!match) {
        return direction
      }
      return match.mdi
    },
  },
  data() {
    return {
      activeLegIndex: -1
    }
  },
  computed: {
    destination () {
      if (!this.tripDetails.legs) {
        return {}
      }
      return {
        name: this.tripDetails.legs[0].to.name,
        lat: this.tripDetails.legs[0].to.lat,
        lon: this.tripDetails.legs[0].to.lon
      }
    },
    steps () {
      if (!this.tripDetails.legs) {
        return []
      }
      return this.tripDetails.legs[0].steps
    }
  },
  methods: {
    viewStepOnMap (lat, lng, place) {
      this.$store.commit('map/SET_POPUP', { active: false })
      this.$store.commit('map/SET_MAP_LATLNG', { lat, lng })
      setTimeout(() => {
        this.$store.commit('map/SET_POPUP', { active: true, content: `<strong>${place}</strong>`, latLng: [lat, lng] })
      }, 200)
    },
    findLegFare (leg) {
      if (!this.tripDetails.fare) {
        return
      }
      let fare = this.tripDetails.fare.details.regular.find(f => f.routes.find(r => r === leg.routeId))
      if (!fare.price) {
        return
      }
      return Math.round((fare.price.cents || 0)/100)
    },
    // ...mapMutations({
    //   viewStepOnMap: 'SET_MAP_LATLNG',
    // }),
    showSteps (legIndex = -1) {
      this.activeLegIndex = legIndex === this.activeLegIndex ? -1 : legIndex
    },
    // viewStepOnMap (lat, lng) {
    //   console.log("viewStepOnMap -> viewStepOnMap (lat, lng)", lat, lng)
    //   this.$store['map'].commit('SET_MAP_LATLNG', { lat, lng })
    // }
  },
}
</script>

<style lang="scss" scoped>
ul {
  padding-left: 0;
}
.trip-details li {
  list-style-type: none;
  transition: all 0.2s;
  padding: 10px 0;
  cursor: pointer;
}
.trip-details li:hover {
  background-color: #ddd;
}
// .trip-details li:last-child:hover {
//   background-color: transparent;
//   cursor: default;
// }
.direction-icon {
  width: 45px;
  text-align: center;
}
.direction-text {
  text-transform: capitalize;
}
.direction-distance {
  font-size:0.84rem;
  opacity: 0.7;
  text-transform: lowercase;
}




</style>