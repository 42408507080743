<template>
<div>
  <div class="trip-summary d-flex" @click="viewTripDetails(tripIndex)">
    <div class="trip-duration">
      <span style="display:inline-block" v-if="trip.legs.length && trip.legs[0].mode === 'UBER'">~</span>
      {{ trip.duration | formatDuration }}<span>min</span>
      <div class="trip-costs" v-if="trip.legs.length && trip.legs[0].mode !== 'UBER'">₦ {{ trip | formatCosts }} </div>
    </div>
    <div class="flex-grow-1">
      <div
        v-for="(leg, legIndex) in trip.legs"
        :key="'leg-' + legIndex"
        :class="{ 'leg-mode': true, 'mode-transit': leg.mode !== 'WALK' }">
          <template v-if="leg.mode === 'WALK'">
            <v-icon title="Walk" :smallNA="(legIndex > 0 && legIndex < trip.legs.length-1)">mdi-walk</v-icon>
          </template>
          <template v-else-if="leg.mode === 'BUS'">
          <v-icon title="Bus">mdi-bus</v-icon>
          <span class="route-no">{{ leg.routeShortName }}</span>
          </template>
          <template v-else-if="leg.mode === 'TRAM'">
          <v-icon title="Tram">mdi-tram</v-icon>
          <span class="route-no">{{ leg.routeShortName || leg.headsign }}</span>
          </template>
          <template v-else-if="leg.mode === 'CAR' || leg.mode === 'DRIVE'">
          <v-icon title="Drive">mdi-car</v-icon>
          </template>
          <template v-else-if="leg.mode === 'UBER'">
          <!-- <img src="@/assets/uber_logo_icon_144780.svg" alt="UBER"> -->
          <v-btn
            color="black white--text"
            large
            depressed
            block
            :title="uberButtonURL">
            <img
              class="uber-logo"
              src="@/assets/uber_logo_icon_white.svg"
              alt="UBER">
            RIDE WITH UBER
          </v-btn>

          </template>
          <template v-else>
            {{ leg.mode }}
          </template>
      </div>
      <div v-if="trip.legs[0].mode === 'CAR' || trip.legs[0].mode === 'DRIVE' || trip.legs[0].mode === 'UBER'">
        <div class="trip-carbon mr-3">
          <v-icon title="Carbon emissions" small>mdi-earth</v-icon> {{ (trip.legs[0].distance * co2KGperMetre).toFixed(1) }} kg
        </div>
        <div class="trip-distance">
          <v-icon title="Distance (km)" small>mdi-sign-direction</v-icon>
          {{ trip.legs[0].distance | formatDistance }} km
        </div>
      </div>
      <div v-else>
        <div class="trip-times">
          <v-icon title="Departure/Arrival times" small>mdi-clock</v-icon>
          {{ trip.startTime | formatTime }} - {{ trip.endTime | formatTime }}
        </div>
        <div class="walk-duration mt-3">
          <v-icon title="Walk time" small>mdi-walk</v-icon>
          {{ trip.legs
              .filter(leg => leg.mode === 'WALK')
              .reduce((total,leg) => total + ((leg.endTime - leg.startTime)/1000), 0)
            | formatDuration
            }} min</div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { timeConverter } from '../helpers/utils'

export default {
  props: {
    origin: {
      type: Object,
      default: () => ({ lat: 0, lng: 0, name: '' })
    },
    destination: {
      type: Object,
      default: () => ({ lat: 0, lng: 0, name: '' })
    },
    trip: {
      type: Object,
      default: () => {}
    },
    tripIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      co2KGperkm: 0.2,
      co2KGperMetre: 0.2/1000
    }
  },
  computed: {
    uberButtonURL () {
      let { lat:origLat, lng:origLng, name:origName } = this.origin
      let { lat:destLat, lng:destLng, name:destName } = this.destination
      let url = `https://m.uber.com/?client_id=${process.env.VUE_APP_UBER_CLIENT_ID}&action=setPickup&pickup[latitude]=${origLat}&pickup[longitude]=${origLng}&pickup[nickname]=${origName}&pickup[formatted_address]=${origName}&dropoff[latitude]=${destLat}&dropoff[longitude]=${destLng}&dropoff[nickname]=${destName}&dropoff[formatted_address]=${destName}&product_id=a1111c8c-c720-46c3-8534-2fcdd730040d`
      return url
    }
  },
  filters: {
    formatDuration: function(secs) {
      let res = Math.round(secs/60)
      return res ?  Math.round(secs/60) : 1;
    },
    formatDistance: function(meters = 0) {
      return  (meters/1000).toFixed(1);
    },
    formatTime: function(date) {
      return timeConverter(date);
    },
    formatCosts: function (trip) {
      if (!trip.legs.length) {
        return '-'
      }
      if (trip.legs[0].mode === 'CAR') {
        return Math.round(trip.legs[0].distance * (30/1000)) // 1.6 USD (610 NGN) per gallon @ 21 miles per gallon = 30 NGN per mile
      } else if (trip.legs[0].mode === 'UBER') {
        return trip.legs[0].costs
      }
      let fullFare = Math.round((trip.fare?.fare?.regular?.cents || 0)/100)
      return Math.round(fullFare * 0.5) + "-" + fullFare
    }
  },
  methods: {
    viewTripDetails (tripIndex) {
      console.log("viewTripDetails -> tripIndex", tripIndex)
      // async fetch all transit trip legs (from and to lat/lngs) and get the OTP drive geometry
      if (this.trip.legs.length && this.trip.legs[0].mode === 'UBER') {
        window.open(this.uberButtonURL)
        return
      }
      this.$store.commit('trip-planner/SET_TRIP_INDEX', tripIndex)
      // this.$router.push({ name: 'trip-planner-trips-results', params: { ...this.$route.params, tripIndex } })
    }
  },
}
</script>

<style lang="scss" scoped>
.trip-costs {
  font-size: 12px;
  font-weight: normal;
  color: rgb(165, 141, 1);
}
.trip-summary {
  border-top: solid 1px #eee;
  padding: 15px 0;
  transition: 1s;
  background-color: #fff;
}
.trip-summary:hover {
  background-color: #ddd;
}

.trip-duration {
  width: 70px;
  margin-right: 15px;
  padding: 0 15px;
  font-size:1.5rem;
  font-weight: bold;
  white-space: nowrap;
  text-align: center;
}
.trip-duration span {
  font-size:0.8rem;
  font-weight: normal;
  margin-left: 2px;
  margin-top: -10px;
  display: block;
}
.walk-duration {
  font-size:0.8rem;
  display: inline-block;
}
.leg-mode {
  display: inline-block;
}
.leg-mode::before {
  content:'›';
}
.leg-mode:first-child::before {
  content:'';
}
.trip-times {
  opacity: 0.8;
  display: inline-block;
  font-size: 0.8rem;
  margin-right: 12px;
}
.trip-carbon {
  font-size: 0.8rem;
  display: inline-block;
}
.trip-distance {
  font-size:0.8rem;
  display: inline-block;
}
/* .leg-mode.mode-transit {
  padding: 2px;
  border: solid 1px #ddd;
} */
.route-no {
  /* display: inline-block;
  padding: 0 2px;
  border: solid 1px #ddd; */
  margin-right: 3px;
  font-size: 0.9rem;
}
/* .leg-mode:not([mode-walk]) {
  border: solid 2px red;
} */
.uber-logo {
  border: 1px solid rgb(255, 255, 255);
  height: 26px;
  border-radius: 5px;
  margin-right: 9px;
  padding: 4px;
}
</style>